import axios from '@/plugins/axios';

export const fetchProvinces = async (params) => {
    try {
        const response = await axios.get('/provinces', {
            params
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching headers:', error);
        throw error;
    }
};

export const createProvince = async (regionalData) => {
    const response = await axios.post('/provinces', regionalData);
    return response.data;
  };
  
  export const updateProvince = async (id, regionalData) => {
    const response = await axios.put(`/provinces/${id}`, regionalData);
    return response.data;
  };

  export const deleteProvince = async (id) => {
    const response = await axios.delete(`/provinces/${id}`);
    return response.data;
  };