import axios from '@/plugins/axios';

export const createConcessionaire = async (concessionaireData) => {
  try {
    const response = await axios.post("/concessionaires", concessionaireData);
    return response.data;
  } catch (error) {
    console.error("Error al crear el concesionario:", error);
    throw error;
  }
};

export const checkHabitualist = async (cuit) => {
  try {
    const response = await axios.get('/concessionaires/check-habitualist', {
        params: { cuit: String(cuit) },
    });
    return response.data;
  } catch (error) {
    console.error('Error checking habitualist:', error);
    throw error;
  }
};

