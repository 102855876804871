import { createRouter, createWebHistory } from 'vue-router';
import MainLayout from '../layouts/MainLayout.vue';
import HomeView from '../views/HomeView.vue';
import PreciosView from '../views/precios/PreciosView.vue';
import DirectorView from '../views/director/DirectorView.vue';
import LoginView from '../views/LoginView.vue';
import IndicesView from '../views/indices/IndicesView.vue';
import ContenidoView from '../views/contenidos/ContenidoView.vue';
import ViewTable from '../views/vistas/ViewActions.vue';
import ConcessionaireFormView from '../views/administrador/concesionarios/ConcessionaireFormView.vue';
import RegionalesView from '../views/administrador/ajustes/RegionalsView.vue';
import ProvinciasView from '../views/administrador/ajustes/ProvincesView.vue';
import AreasView from '../views/administrador/ajustes/AreasView.vue';
import CargosView from '../views/administrador/ajustes/PositionView.vue';

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/main',
    component: MainLayout,
    meta: { requiresAuth: true }, 
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView,
      },
      {
        path: 'novedades',
        name: 'Novedades',
        component: () => import(/* webpackChunkName: "novedades" */ '../views/novedades/NovedadesView.vue'),
      },
      {
        path: 'precios/:subMenu', 
        name: 'PreciosSubMenu',
        component: PreciosView,
      },
      {
        path: 'director/:subMenu', 
        name: 'DirectorSubMenu',
        component: DirectorView,
      },
      {
        path: 'contenidos/:subMenu', 
        name: 'ContenidoView',
        component: ContenidoView,
      },
      {
        path: 'indices',
        name: 'Indices',
        component: IndicesView,
        props: { menu: 'indices' },
      },
      {
        path: 'vista', 
        name: 'Vista',
        component: ViewTable,
      },
      {
        path: 'administrador/:subMenu',
        name: 'AdministradorSubMenu',
        component: () => import(/* webpackChunkName: "administrador" */ '../views/administrador/AdministradorView.vue'),
        props: true,
      },

      {
        path: '/concessionaires-alta',
        name: 'ConcessionaireForm',
        component: ConcessionaireFormView,
        meta: { requiresAuth: true },
      },
      {
        path: 'administrador/ajustes/regionales',
        name: 'Regionales',
        component: RegionalesView,
      },
      {
        path: 'administrador/ajustes/provincias',
        name: 'Provincias',
        component: ProvinciasView,
      },
      {
        path: 'administrador/ajustes/areas',
        name: 'Areas',
        component: AreasView,
      },
      {
        path: 'administrador/ajustes/cargos',
        name: 'Cargos',
        component: CargosView,
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('user_access_token'); 

  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next({ name: 'login' });
  } else if (to.name === 'login' && isAuthenticated) {
    next({ name: 'home' });
  } else {
    next();
  }
});

export default router;