<template>
  <div>
    <div class="form-group custom-width">
      <label for="cuit">CUIT del Comerciante Habitualista</label>
      <input
        type="text"
        id="cuit"
        class="form-control"
        :value="habitualistaData.cuit"
        disabled
      />
    </div>

    <div class="separator-line"></div>

    <div class="form-row">
      <div class="form-group col">
        <label for="client_code">Código de Cliente</label>
        <input
          type="text"
          id="client_code"
          class="form-control"
          v-model="client_code"
        />
      </div>

      <div class="form-group col">
        <label for="member_number">Número de Socio</label>
        <input
          type="text"
          id="member_number"
          class="form-control"
          v-model="member_number"
        />
      </div>

      <div class="form-group col">
        <label for="business_name">Razón Social</label>
        <input
          type="text"
          id="business_name"
          class="form-control"
          placeholder="Ingresar Razón Social"
          v-model="business_name"
        />
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col">
        <label for="province_id">Provincia</label>
        <div class="select-wrapper">
          <select
            id="province_id"
            class="form-control select-style"
            v-model="province_id"
          >
            <option value="">Seleccionar Provincia</option>
            <option
              v-for="province in provinces.data"
              :key="province.id"
              :value="province.id"
            >
              {{ province.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group col">
        <label for="locality">Localidad</label>
        <input
          type="text"
          id="locality"
          class="form-control"
          placeholder="Ingresar Localidad"
          v-model="locality"
        />
      </div>

      <div class="form-group col">
        <label for="regional_id">Regional</label>
        <div class="select-wrapper">
          <select
            id="regional_id"
            class="form-control select-style"
            v-model="regional_id"
          >
            <option value="">Seleccionar Regional</option>
            <option
              v-for="regional in regionals"
              :key="regional.id"
              :value="regional.id"
            >
              {{ regional.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col">
        <label for="phone">Teléfono</label>
        <input
          type="text"
          id="phone"
          class="form-control"
          placeholder="Ingresar Teléfono"
          v-model="phone"
        />
      </div>

      <div class="form-group col">
        <label for="email">Email</label>
        <input
          type="email"
          id="email"
          class="form-control"
          placeholder="Ingresar Correo Electrónico"
          v-model="email"
        />
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col">
        <label for="vehicle_type">Tipo de Vehículo</label>
        <div class="select-wrapper">
          <select
            id="vehicle_type"
            class="form-control select-style"
            v-model="vehicle_type"
            @change="handleVehicleTypeChange"
          >
            <option value="">Seleccionar Tipo de Vehículo</option>
            <option
              v-for="type in vehicleTypes"
              :key="type.id"
              :value="type.id"
            >
              {{ type.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group col">
        <label for="brand_id">Marca</label>
        <div class="select-wrapper">
          <select
            id="brand_id"
            class="form-control select-style"
            v-model="brand_id"
          >
            <option value="">Seleccionar Marca</option>
            <option v-for="brand in brands" :key="brand.id" :value="brand.id">
              {{ brand.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

   <!--  <div class="form-group d-flex align-items-center">
      <label for="agregarSucursales" class="mr-2">Agregar Sucursales</label>
      <input
        type="checkbox"
        id="agregarSucursales"
        class="custom-switch"
        v-model="agregarSucursales"
      />
    </div> -->

    <div class="button-container d-flex justify-content-between">
      <button
        @click="prevStep"
        class="continue-button back-button small-button"
      >
        ANTERIOR
      </button>
      <button @click="submitForm" class="continue-button small-button">
        CREAR CONCESIONARIO
      </button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { fetchProvinces } from "@/services/api/provinceService";
import { fetchRegionals } from "@/services/api/regionalService";
import { fetchBrandList } from "@/services/api/brandService";
import { vehicleTypes } from "@/services/api/vehicleTypes";

export default {
  name: "ConcessionaireFormStepTwo",
  props: {
    habitualistaData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const client_code = ref(props.habitualistaData?.client_code || "");
    const member_number = ref(props.habitualistaData?.member_number || "");
    const business_name = ref(props.habitualistaData?.business_name || "");
    const province_id = ref(props.habitualistaData?.province_id || "");
    const locality = ref(props.habitualistaData?.locality || "");
    const street_name = ref(props.habitualistaData?.street_name || "");
    const street_number = ref(props.habitualistaData?.street_number || "");
    const postal_code = ref(props.habitualistaData?.postal_code || "");
    const phone = ref(props.habitualistaData?.phone || "");
    const email = ref(props.habitualistaData?.email || "");
    const website = ref(props.habitualistaData?.website || "");
    const brand_id = ref(props.habitualistaData?.brand_id || "");
    const regional_id = ref(props.habitualistaData?.regional_id || "");
    const agregarSucursales = ref(false);
    const vehicle_type = ref("");

    const provinces = ref([]);
    const regionals = ref([]);
    const brands = ref([]);

    const vehicleTypesList = vehicleTypes;

    onMounted(async () => {
      try {
        provinces.value = await fetchProvinces();
        regionals.value = await fetchRegionals();
      } catch (error) {
        console.error("Error al cargar provincias o regionales:", error);
      }
    });

    watch(
      () => props.habitualistaData,
      (newData) => {
        client_code.value =
          newData?.client_code === "null" ? "" : newData?.client_code || "";
        member_number.value =
          newData?.member_number === "null" ? "" : newData?.member_number || "";
        business_name.value =
          newData?.business_name === "null" ? "" : newData?.business_name || "";
        province_id.value =
          newData?.province_id === "null" ? "" : newData?.province_id || "";
        locality.value =
          newData?.locality === "null" ? "" : newData?.locality || "";
        street_name.value =
          newData?.street_name === "null" ? "" : newData?.street_name || "";
        street_number.value =
          newData?.street_number === "null" ? "" : newData?.street_number || "";
        postal_code.value =
          newData?.postal_code === "null" ? "" : newData?.postal_code || "";
        phone.value = newData?.phone === "null" ? "" : newData?.phone || "";
        email.value = newData?.email === "null" ? "" : newData?.email || "";
        website.value =
          newData?.website === "null" ? "" : newData?.website || "";
        brand_id.value =
          newData?.brand_id === "null" ? "" : newData?.brand_id || "";
        regional_id.value =
          newData?.regional_id === "null" ? "" : newData?.regional_id || "";
      },
      { immediate: true }
    );

    const handleVehicleTypeChange = async () => {
      if (vehicle_type.value) {
        try {
          brands.value = await fetchBrandList(vehicle_type.value);
        } catch (error) {
          console.error("Error al obtener lista de marcas:", error);
        }
      } else {
        brands.value = [];
      }
    };

    const prevStep = () => emit("prev-step");
    const submitForm = () =>
      emit("submit-form", {
        cuit: props.habitualistaData?.cuit || "",
        client_code: client_code.value,
        member_number: member_number.value,
        business_name: business_name.value,
        province_id: province_id.value,
        locality: locality.value,
        street_name: street_name.value,
        street_number: street_number.value,
        postal_code: postal_code.value,
        phone: phone.value,
        email: email.value,
        website: website.value,
        brand_id: brand_id.value,
        regional_id: regional_id.value,
        agregarSucursales: agregarSucursales.value,
      });

    return {
      client_code,
      member_number,
      business_name,
      province_id,
      locality,
      street_name,
      street_number,
      postal_code,
      phone,
      email,
      website,
      brand_id,
      regional_id,
      agregarSucursales,
      prevStep,
      submitForm,
      provinces,
      regionals,
      brands,
      vehicle_type,
      vehicleTypes: vehicleTypesList,
      handleVehicleTypeChange,
    };
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.form-row .col {
  flex: 1;
}

.separator-line {
  height: 1px;
  background-color: #ddd;
  margin: 20px 0;
}

.select-wrapper {
  position: relative;
}

.select-style {
  appearance: none;
  background-color: #f0f0f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-right: 25px;
}

.select-wrapper::after {
  content: "▾";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 0.875rem;
  color: #555;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.continue-button {
  background-color: #0056b3;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  font-size: 0.875rem;
}

.small-button {
  font-size: 0.875rem;
  padding: 8px 16px;
}

.custom-switch {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.custom-width {
  width: 32% !important;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }

  .button-container {
    flex-direction: column;
    gap: 10px;
  }

  .continue-button {
    width: 100%;
    max-width: 200px;
  }

  .select-style {
    background-color: #eef2f9;
  }

  .small-button {
    padding: 10px 16px;
  }
}
</style>
